@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");
.ticket-mockup {
  print-color-adjust: exact;
  font-family: "Staatliches", cursive;
  color: black;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin: 25px 0;
  max-width: 706px;

  .ticket-box {
    margin: auto;
    display: flex;
    background: rgba(212, 213, 213, 0.3098039216);
    border-radius: 20px;

    /* width: 672px; */
    background: linear-gradient(319deg, #11a187 15%, rgb(69, 204, 111) 64%);
  }

  .left {
    display: flex;
    position: relative;
    /* width: 641px; */
    justify-content: center;
    padding: 0px 20px;
    &::after {
      content: "";
      width: 35px;
      height: 35px;
      background: white;
      border-radius: 50%;
      position: absolute;
      top: -15px;
      z-index: 999;
      right: -17px;
    }
    &::before {
      content: "";
      width: 35px;
      height: 35px;
      background: white;
      border-radius: 50%;
      position: absolute;
      bottom: -15px;
      z-index: 999;
      right: -17px;
    }

    .ticket-number {
      height: 250px;
      width: 250px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 5px;
    }
  }

  .image {
    display: none;
    background-size: cover;
    opacity: 0.85;
    background-position: top center;
  }

  .ticket-info {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
  }

  .date {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 5px 0;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0;
    p {
      margin: 0;
    }
  }

  .date span {
    width: 100px;
    color: #fff;
  }

  .date span:first-child {
    text-align: left;
  }

  .date span:last-child {
    text-align: right;
  }

  .date .june-29 {
    color: #ffeb3b;
    font-size: 20px;
  }

  .show-name {
    font-size: 32px;
    color: #ffeb3b;
  }

  .show-name h1 {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #ffeb3b !important;
  }
  .show-name h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0;
  }
  .time {
    padding: 10px 0;
    color: #ffeb3b;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 700;
    p {
      margin: 0;
    }
  }

  .time span {
    font-weight: 400;
    color: gray;
  }

  .left .time {
    font-size: 16px;
  }

  .location {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding-top: 8px;
    border-top: 1px solid #fff;
    span {
      color: #fff;
    }
  }

  .location .separator {
    font-size: 20px;
  }

  .right {
    display: flex;

    width: 180px;
    border-left: 1px dashed #fff;
    align-items: center;

    justify-content: space-around;
  }

  .right .right-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right .show-name h1 {
    font-size: 18px !important;
  }

  .barcode {
    height: 100px;
  }

  .barcode img {
    height: 100%;
  }

  .right .ticket-number {
    color: #000;
  }
}
