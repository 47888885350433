@import "./links";

body {
  font-family: "Cairo", sans-serif;
  background: $color-body;
}

.padding-section {
  padding: 25px 0;
}

.form-label {
  //! Reset label input
  color: #0e0e0e;
  font-weight: 600;
}

.form-control,
.form-select {
  //! Reset Input
  border: none;
  border-bottom: 1px solid #a9a9a9;
  border-radius: 0;
  &:focus {
    box-shadow: none;
    border-bottom-color: #080706;
  }
}

.form-control-error {
  border: 1px solid #e72222 !important;
  border-bottom: 3px solid #e72222 !important;
}
.error-text {
  color: #e72222;
  font-size: 14px;
  margin-top: 8px;
}

.form-check-input:checked {
  background-color: #060405;
  border-color: #0e0e0e;
}
.form-check-input {
  margin-right: 5px;
}

.invalid-feedback {
  display: block !important;
}
