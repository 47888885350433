.my-tickets {
  background: #f9fbfc;
  padding: 148px 0;

  .tickets {
    padding: 50px;
    background-color: #fff;
    .ticket-item {
      .ticket-header {
        background: #fff;
        padding: 35px;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #eee;
        color: #1d244e;
        font-weight: 600;
        cursor: pointer;
        .ticket-event {
          display: flex;
          justify-content: space-between;
          align-items: center;
          left: 0px;
          position: relative;
          transition: left 0.5s ease;
        }

        .event-title {
          text-transform: uppercase;
        }
        .icon-plus {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid #1d244e;
          font-size: 22px;
          line-height: 100%;
          transition: all 0.5s ease;
        }
        &:hover {
          background: #f7f7f7;
          .ticket-event {
            left: 15px !important;
          }
          .icon-plus {
            background: #1d244e;
            color: #fff;
          }
        }
      }

      .ticket-body {
        th {
          white-space: nowrap;
        }
        button {
          border-radius: 30px;

          border: none;
          &.download {
            background: #e91e63 !important;
          }
          &.send-mail {
            background: #8bc34a !important;
          }
        }
      }
    }
  }
}
