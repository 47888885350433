/*
.navigation-responsive {
  .navigation-responsive-col-fx {
    background: #000;
    display: block;
    position: fixed;
    top: 0;
    cursor: pointer;
    z-index: 800;
    left: 20px;
    width: 100%;
    left: 0;
    padding: 20px;
  }
  nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 22px;
  }
}*/

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  z-index: 999;
  .navigation-responsive-col-fx {
    display: none;
  }
  .toolbar-confirm-email {
    background: #f44336;
    padding: 8px;
    color: #fff;
    animation: color-transition 2s ease-in-out infinite;
    font-weight: 700;
    span {
      text-decoration: underline;
      cursor: pointer;
      color: white;
    }
    @keyframes color-transition {
      0% {
        background-color: #dc3545;
      }
      50% {
        background-color: #9a2230;
      }
      100% {
        background-color: #dc3545;
      }
    }
  }
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 10px 0;

    .links {
      ul {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 0;

        .styled-link {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          position: relative;
          display: block;
          text-transform: uppercase;
          padding: 10px 20px;
          text-decoration: none;
          color: #fff;
          z-index: 1;
        }
        .normal-link {
          border-radius: 25px;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 14px;
          color: #fff;
          padding: 10px;
          transition: background 0.3s ease;
          &:hover {
            background: #81b441;
          }
        }
      }
    }
    .nav-column-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        font-weight: bold;
        color: #fff;
      }
    }
    .nav-column-2 {
      flex-grow: 1;
      .flags {
        display: flex;
        gap: 5px;
        @include ltr_direction(margin-left, 10px);
        a {
          cursor: pointer;
        }
        img {
          width: 26px;
          padding: 2px;
          &.active {
            border: 2px solid #81b441;
            border-radius: 50%;
          }
          &:hover {
            @extend .active;
          }
        }
      }
      ul {
        padding: 0;
        li {
          position: relative;
        }
        .sub-menu {
          display: none;
          background: white;
          position: absolute;
          top: 36px;
          flex-direction: column;
          width: 100%;
          padding: 12px 0;
          border-radius: 8px;
          &.show {
            display: flex;
          }
        }
      }
    }
  }
}

//Responsive

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 662px) {
  .toolbar-confirm-email {
    position: fixed;
    left: 0;
    right: 0;
    top: 30px;
    z-index: 99999;
    top: 69px;
    z-index: 99999;
    font-size: 12px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    padding: 0;
    visibility: hidden;

    &.show {
      visibility: visible;
    }
    .navigation-responsive-col-fx {
      display: block;
      visibility: visible;
      background: rgb(0, 0, 0);
      position: fixed;
      top: 0px;

      width: 100%;
      padding: 20px 0 20px 30px;
      @include ltr_direction(padding, 20px 0 20px 30px);
      @include rtl_direction(padding, 20px 30px 20px 0);
      &.light {
        background: #fff;
        .nav-hamburger {
          div {
            background-color: #45cc6f !important;
          }
        }
      }
    }
    .nav-column-1 {
      flex-direction: column;
      margin-bottom: 25px !important;
    }
    .nav-column-2 {
      position: fixed;
      top: 20px;
      @include ltr_direction(left, 70px);
      @include rtl_direction(right, 70px);
      visibility: visible;
    }
    .links ul {
      padding: 0;
      flex-direction: column;
      gap: 25px !important;

      .normal-link {
        font-size: 22px !important;
        color: #45cc6f !important;
        padding: 5px 30px !important;
        &:hover {
          background: #e7e7e7 !important;
        }
      }
      .styled-link {
        font-size: 22px !important;
        &.link-style-2 {
          color: #45cc6f;
        }
      }
    }
  }
}
@media only screen and (min-width: 662px) and (max-width: 768px) {
  .toolbar-confirm-email {
    position: fixed;
    left: 0;
    right: 0;
    top: 69px;
    z-index: 99999;
    font-size: 12px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    padding: 0;
    visibility: hidden;

    &.show {
      visibility: visible;
    }
    .navigation-responsive-col-fx {
      display: block;
      visibility: visible;
      background: rgb(0, 0, 0);
      position: fixed;
      top: 0px;

      width: 100%;
      padding: 20px 0 20px 30px;
      @include ltr_direction(padding, 20px 0 20px 30px);
      @include rtl_direction(padding, 20px 30px 20px 0);
      &.light {
        background: #fff;
        .nav-hamburger {
          div {
            background-color: #45cc6f !important;
          }
        }
      }
    }
    .nav-column-1 {
      flex-direction: column;
      margin-bottom: 25px !important;
    }
    .nav-column-2 {
      position: fixed;
      top: 20px;
      @include ltr_direction(left, 70px);
      @include rtl_direction(right, 70px);
      visibility: visible;
    }
    .links ul {
      padding: 0;
      flex-direction: column;
      gap: 25px !important;

      .normal-link {
        font-size: 22px !important;
        color: #45cc6f !important;
        padding: 5px 30px !important;
        &:hover {
          background: #e7e7e7 !important;
        }
      }
      .styled-link {
        font-size: 22px !important;
        &.link-style-2 {
          color: #45cc6f;
        }
      }
    }
  }
}
