.btn-form {
  padding: 12px 35px;
  color: #fff;
  background-color: $color-blue;
  border-color: $color-blue;

  &:hover {
    background-color: darken($color: $color-blue, $amount: 20%);
    color: #fff;
  }
}
