/*
====================
       COLORS
====================
*/

// Primary
$color-1: #11a187;
$color-2: #45cc6f;
$color-3: #81b441;

$color-orange: #ff5722;
$color-red: #e91e63;
$color-blue: #3a2fe1;

$color-body:#f9fbfc
