.timer {
  margin: 30px 0;
  display: flex;
  justify-content: space-around;
  color: #fff;
  & > div {
    display: flex;
    flex-direction: column;
    @include ltr_direction(margin-right, 40px);
    @include rtl_direction(margin-left, 40px);
    &.seconds {
      @include ltr_direction(margin-right, 0 !important);
      @include rtl_direction(margin-left, 0 !important);
    }
    span:first-child {
      font-size: 45px;
    }
    span:last-child {
      font-size: 18px;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 662px) {
  .timer {
    & > div:not(:last-child) {
      @include ltr_direction(margin-right, 18px);
      @include rtl_direction(margin-left, 18px);
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 662px) and (max-width: 768px) {
  /* Global */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
