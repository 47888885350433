.thankyou {
  background: #f9fbfc;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  #confetti-holder {
    position: fixed;
  }

  .thankyou-box {
    margin: auto;
    background: rgba(255, 255, 255, 0.4);
    padding: 35px 35px;
    border-radius: 8px;
    backdrop-filter: blur(5px);
    box-shadow: 1px 6px 9px 0px rgb(0 0 0 / 2%);
    width: 77%;
    position: relative;
    overflow: hidden;
    .checkmark {
      color: #8bc34a;

      font-size: 40px;
      display: block;
      text-align: center;
      margin-bottom: 10px;
    }
    h1 {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: #8bc34a;
    }

    .orderNumber {
      text-align: center;
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: darkgrey;
    }
    .email-sent {
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 15px;
      color: #282828;
      font-size: 14px;
    }
    .buttons {
      text-align: center;

      .btn-ticket,
      .btn-receipt {
        padding: 14px 20px;
        color: #fff;
      }
      .btn-ticket {
        margin-right: 15px;
        background-color: #11a187;
        border-color: #11a187;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba($color: #11a187, $alpha: 0.5);
        }
      }
    }

    .btn-receipt {
      background-color: #dc3545;
      border-color: #dc3545;
      &:focus {
        box-shadow: 0 0 0 0.25rem rgba($color: #dc3545, $alpha: 0.5);
      }
    }

    .lines-1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;

      border-left: 5px solid #3f51b5;
      border-top: 5px solid #3f51b5;
    }
    .lines-2 {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;

      border-right: 5px solid #03a9f4;
      border-top: 5px solid #03a9f4;
    }
    .lines-3 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 40px;
      /* background: red; */
      border-left: 5px solid #9c27b0;
      border-bottom: 5px solid #9c27b0;
    }
    .lines-4 {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 40px;
      /* background: red; */
      border-right: 5px solid #ffc107;
      border-bottom: 5px solid #ffc107;
    }
  }

  .new-order {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    .link-new-order {
      color: #dc3545;
      font-size: 17px;
      text-align: center;
      display: block;
      margin-top: 10px;
    }
  }
}
/* Responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 662px) {
  .thankyou {
    .thankyou-box {
      background: rgba(255, 255, 255, 0.4);
      box-shadow: none;
      width: auto;
      backdrop-filter: blur(5px);
    }

    .your-ticket {
      display: none;
    }
    .ticket-mockup {
      display: none;
    }

    .buttons .btn-ticket {
      margin-right: 0;
      margin-bottom: 15px;
    }

    .new-order {
      .link-new-order {
        font-size: 14px;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 662px) and (max-width: 768px) {
  /* Global */
  .thankyou {
    .container {
      width: auto !important;
    }
    .thankyou-box {
      box-shadow: none;
      width: auto;
      padding: 0;
    }

    .buttons .btn-ticket {
      margin-right: 0;
      margin-bottom: 15px;
    }

    .new-order {
      .link-new-order {
        font-size: 14px;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .thankyou {
    .container {
      width: auto !important;
    }
    .thankyou-box {
      padding: 0;
    }
  }
}
