.fixture {
  display: block;
  width: 100%;
  padding: 0 50px;

  &__wrap {
    display: flex;
    width: 100%;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -ms-flex-pack: center;
    justify-content: center;
  }
  &__home-club,
  &__away-club {
    z-index: 9999;
    min-width: 144px;
    max-width: 160px;

    img {
      height: auto;
    }
  }
  &__home-club {
    img {
      @include ltr_direction(margin-right, -32px);
      @include rtl_direction(margin-left, -32px);
    }
  }
  &__away-club {
    img {
      @include ltr_direction(margin-left, -32px);
      @include rtl_direction(margin-right, -32px);
    }
  }
  &__info {
    display: flex;
    width: 53%;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
  &__wrap-date {
    display: block;
    padding: 16px 20px;
    background: #81b441;
    position: relative;
    color: #fff;

    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
  }
  &__date {
    font-size: 16px;
    &::before {
      content: "";
      display: block;
      background: transparent;
      border-top: 68px solid #81b441;
      border-left: 14px solid transparent;
      position: absolute;
      top: 0;
      left: -14px;
      z-index: 100;
      height: 100%;
    }
    &::after {
      content: "";
      display: block;
      background: transparent;
      border-top: 68px solid #81b441;
      border-right: 14px solid transparent;
      position: absolute;
      top: 0;
      right: -14px;
      z-index: 100;
      height: 100%;
    }
  }
  &__match {
    z-index: 100;
    display: flex;
    width: 100%;
    background: #fff;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-bottom: 10px solid #81b441;
  }
  &__column-event-name {
    padding: 20px;
    font-size: 22px;
    font-weight: 700;
    color: $color-3;
  }
  &__column-left,
  &__column-right {
    width: 37%;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    position: relative;
    min-height: 1px;
    h3 {
      margin: 0.5rem 0;
      font-size: 20px;
      font-weight: bold;
    }
  }
  &__wrap-text-home-club {
    text-align: right;
    padding-right: 0;
  }
  &__text-home-club {
    width: 100%;
    display: block;
    overflow: hidden;
    font-size: 30px;
    font-weight: 600;
    color: #262626;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__column-center {
    width: 15%;
    text-align: center;
    display: block;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  &__versus {
    display: inline-block;
    position: relative;
    color: #fff;
    padding: 28px 15px;
    text-align: center;
    background: #81b441;
    &::before {
      content: "";
      display: block;
      background: transparent;
      border-top: 80px solid #81b441;
      border-left: 31px solid transparent;
      position: absolute;
      top: 0;
      left: -31px;
      z-index: 100;
      height: 100%;
    }
    &::after {
      content: "";
      display: block;
      background: transparent;
      border-top: 80px solid #81b441;
      border-right: 31px solid transparent;
      position: absolute;
      top: 0;
      right: -31px;
      z-index: 100;
      height: 100%;
    }
  }
  &__stadium {
    display: block;
    padding: 16px 40px;
    background: #fff;
    position: relative;
    color: #262626;

    letter-spacing: 0;
    text-transform: uppercase;
  }
  &__text-stadium {
    font-size: 14px;
    font-weight: 600;
    &::before {
      content: "";
      display: block;
      background: transparent;
      border-top: 50px solid #fff;
      border-left: 14px solid transparent;
      position: absolute;
      top: 0;
      left: -14px;
      z-index: 100;
      height: 100%;
    }
    &::after {
      content: "";
      display: block;
      background: transparent;
      border-top: 50px solid #fff;
      border-right: 14px solid transparent;
      position: absolute;
      top: 0;
      right: -14px;
      z-index: 100;
      height: 100%;
    }
  }
}

/* Responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 662px) {
  .fixture {
    padding: 0;
    &__info {
      width: 100%;
    }
    &__home-club,
    &__away-club {
      display: none;
    }
    &__column-center {
      width: auto;
      div {
        &::after {
          border-right: 10px solid transparent;
          right: -10px;
          top: 0;
        }
        &::before {
          border-left: 10px solid transparent;
          left: -10px;
          top: 0;
        }
      }
    }
    &__column-left,
    &__column-right {
      h3 {
        font-size: 14px;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 662px) and (max-width: 768px) {
  /* Global */
  .fixture {
    padding: 0;
    &__info {
      width: 100%;
    }
    &__home-club,
    &__away-club {
      display: none;
    }

    &__column-left,
    &__column-right {
      h3 {
        font-size: 15px;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .fixture {
    padding: 0;
    &__info {
      width: 100%;
    }

    &__column-left,
    &__column-right {
      h3 {
        font-size: 16px;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .fixture__info {
    width: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
