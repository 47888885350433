.paymentfailed {
  background: #dc3545;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .paymentfailed-box {
    margin: auto;
    background: rgba(255, 255, 255, 0.4);
    padding: 35px 35px;
    box-shadow: 1px 6px 9px 0px rgb(0 0 0 / 2%);
    width: 77%;
    position: relative;
    overflow: hidden;
    background: #fff;
    i {
      color: #dc3545;
      font-size: 40px;
      display: block;
      text-align: center;
      margin-bottom: 10px;
    }
    h1 {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: #dc3545;
    }
    p {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }
    .lines-1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-left: 5px solid #bf2534;
      border-top: 5px solid #bf2534;
    }
    .lines-2 {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-right: 5px solid #bf2534;
      border-top: 5px solid #bf2534;
    }
    .lines-3 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-left: 5px solid #bf2534;
      border-bottom: 5px solid #bf2534;
    }
    .lines-4 {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-right: 5px solid #bf2534;
      border-bottom: 5px solid #bf2534;
    }
  }

  .btn-redirect {
    a {
      color: #dc3545;
      font-size: 17px;
      text-align: center;
      display: block;
      margin-top: 10px;
    }
  }
}
