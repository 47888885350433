/* Responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 662px) {
  .loging {
    height: auto !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 662px) and (max-width: 768px) {
  .loging {
    height: auto !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .loging {
    height: auto !important;
  }
}

//*** Responsive Height **/

@media only screen and (min-height: 850px) {
  .login {
    height: 100vh;
  }
}
