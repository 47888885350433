.verify-ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .verify-ticket-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 450px;

    input {
      padding: 0.5rem;
      border: none;

      margin-right: 0.5rem;
      font-size: 1rem;
      background: #e7e7e7;
      width: 100%;
      margin-bottom: 10px;
      border-bottom: 3px solid #2196f3;
    }

    button {
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 0.25rem;
      background-color: #0077ff;
      color: #fff;
      font-size: 1rem;
      cursor: pointer;
    }
  }

  .verify-ticket-loading {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .verify-ticket-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;

    .verify-ticket-info-row {
      display: flex;
      margin-bottom: 0.5rem;

      .verify-ticket-info-label {
        font-weight: bold;
        margin-right: 0.5rem;
      }

      .verify-ticket-info-value {
        font-weight: normal;
      }
    }

    .verify-ticket-validate {
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 0.25rem;
      background-color: #198754;
      color: #fff;
      font-size: 1rem;
      cursor: pointer;
    }
    .verify-ticket-verification-status {
      color: white;
      margin-top: 1rem;
      background: #f44336;
      padding: 10px 20px;
      border-radius: 5px;
    }
  }
}
