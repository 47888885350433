.footer {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  font-size: 14px;
  a {
    text-decoration: none;
    color: $color-3;
  }
}
