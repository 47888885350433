.booking {
  padding: 148px 0;
  background: #f9fbfc;

  .title {
    font-size: 32px;
    font-weight: 600;
  }

  .sub-title {
    color: #afafaf;
    font-size: 16px;
    font-weight: normal;
    margin: 15px 0;
    margin-bottom: 40px;
  }
  .spectator-field {
    margin: auto;
    background: #ffffff;
    padding: 35px 35px;
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 1px 6px 9px 0px rgb(0 0 0 / 15%);
    .spectator-feild-to-choose{
      display: flex;
      align-items: center;
      gap: 5px;
      
      label{
        background-color: #3a2fe1;
    width: 60%;
    padding: 10px 25px;
    color: #fff;
      }
      input{
        background: #e9ecef;
        margin-bottom: 0;
        padding: 10px;
        font-weight: bold;
        width: 60px;
        border: 1px solid #979fa7;
        border-radius: 5px;
      }

    }
    
    .ticket-price {
      margin-top: 25px;
      display: inline-block;
      background: #e91e63;
      font-weight: bold;
      padding: 8px 12px;
      color: #fff;
    }
    .spectator-count {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }


  }

  form {
    .form-label {
      color: #898b8b;
      font-weight: 400;
      margin-bottom: 0px;
    }

    .form-control,
    .form-select {
      border: none;
      border-bottom: 2px solid #3a2fe1;
      border-radius: 0;
      padding-left: 0;
      margin-bottom: 15px;
      option {
        font-weight: bold;
      }
      &:focus {
        background-color: #f9fbfc;
      }
    }

    button {
      padding: 12px 35px;
      color: #fff;
      background-color: #3a2fe1;
      border-color: #3a2fe1;
    }
    ::placeholder {
      color: #afb1b1;
    }
  }

  .ticket-information {
    margin: auto;
    background: #ffffff;
    padding: 35px 35px;
    border-radius: 8px;
    margin-top: 30px;
    box-shadow: 1px 6px 9px 0px rgb(0 0 0 / 15%);
  }

  .ticket-information {
    background: linear-gradient(319deg, #e32626  15%, #bf1d1d 64%);
    color: #fff;
    h2 {
      margin-bottom: 25px;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
      i {
        font-size: 16px;
      }
      span {
        color: #fff;
      }
    }

    .ticket-price {
      margin-top: 25px;
      display: inline-block;
      background: #e91e63;
      font-weight: bold;
      padding: 8px 12px;
    }
  }

  .satim-call {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
  }

  .stadium-img {
    img {
      width: 280px;
      text-align: center;
      display: block;
      margin: auto;
      margin-top: 15px;
    }
  }

  .confirm-information {
    .field {
      background: #f9fbfc;
      border: 3px solid #3a2fe1;
      padding: 5px;
      color: #3a2fe1;
      font-weight: 600;
    }
    button.btn-confirmation {
      margin-right: 8px;
    }
    button.btn-edit {
      background-color: #4caf50;
      border-color: #4caf50;
    }
  }
}
