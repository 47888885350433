.link-style-1 {
  background: #a3d960;
  border-radius: 28px;
  box-shadow: inset 0px -6px 0px 0px #56762d;
  border: none;
}

.link-style-2 {
  border: 2px solid;
  border-radius: 28px;
}
