.stepper {
  display: flex;
  justify-content: center;
  gap: 40px;
  .steps {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    .circle {
      position: relative;
      background: #03a9f4;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 55px;
      color: #fff;
      font-weight: bold;
      color: white;
      border: 2px solid #11a187;
      background: linear-gradient(
        319deg,
        #11a187 15%,
        rgba(69, 204, 111, 1) 64%
      );
      box-shadow: 2px 5px 1rem 1px rgb(0 0 0 / 15%);
      font-size: 38px;
    }
    .text-step {
      font-weight: bold;
      font-size: 19px;
      color: #ffffff;
      text-transform: uppercase;
      &.text-step-fr {
        font-size: 14px !important;
      }
    }
  }

  .steps:not(:last-child) {
    .circle::after {
      content: "";
      position: absolute;
      top: 50%;
      @include ltr_direction(right, -151px);
      @include rtl_direction(left, -151px);

      background-color: #11a187;
      width: 151px;
      height: 8px;
    }
  }
}

/* Responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 662px) {
  .stepper {
    display: block;
    .steps {
      flex-direction: row;
      justify-content: stretch;
      .circle {
        width: 70px;
        height: 70px;

        font-size: 25px;
        &:after {
          display: none;
        }
      }
      .text-step {
        font-size: 16px;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 662px) and (max-width: 768px) {
  .stepper {
    display: block;
    .steps {
      flex-direction: row;
      justify-content: stretch;
      .circle {
        width: 70px;
        height: 70px;

        font-size: 25px;
        &:after {
          display: none;
        }
      }
      .text-step {
        font-size: 16px;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
