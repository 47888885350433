//Hamburger icon
.nav-hamburger {
  display: block;
  cursor: pointer;
  z-index: 999999;
  div {
    width: 30px;
    height: 3px;
    background: #f2f5f7;
    margin: 5px;
    transition: all 0.3s ease;
  }

  &.toggle {
    .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .line2 {
      transition: all 0.7s ease;
      width: 0;
    }
    .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }
}
