.home {
  text-align: center;
}
.construction {
  position: absolute;
  background: #000000bf;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  .contruction-label {
    background-color: #161616;
    height: 90px;
    position: absolute;
    top: 44%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    border-top: 9px solid #fff;
    border-bottom: 9px solid #fff;
    &::after {
      content: "En construction";
      color: #fff;
      z-index: 9999;
      font-size: 42px;
      font-weight: bold;
      text-shadow: -2px 0rem 1rem rgb(0 0 0 / 66%) !important;
      text-align: center;

      position: absolute;
      background: #161616;
      height: 100%;
      padding-top: 5px;
      padding: 5px 15px;
    }
    & > div {
      background: #f8a82b;
      width: 20px;
      height: 131%;
      transform: rotateZ(32deg);
    }
  }
}
