.events {
  margin: 80px 0;
  a {
    color: none;
    text-decoration: none;
  }
  h2 {
    text-transform: uppercase;
    color: #81b441;
    font-weight: 800;
    margin-bottom: 50px;
  }

  .event-card {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    color: #333;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    }

    &__image {
      position: relative;
      overflow: hidden;

      &-img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
      }
    }

    &__content {
      padding: 20px;
      @include ltr_direction(text-align, left);
      @include rtl_direction(text-align, right);
      &-title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;

        a {
          color: #333;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* margin-bottom: 10px; */
        /* justify-content: start; */
        /* gap: 20px; */

        & > div {
          font-size: 14px;
          font-weight: 600;
          color: #767676;
        }
      }
      &-category {
        display: flex;
        gap: 5px;
      }
      .event-cta-modify {
        background: #8bc34a;
      }
      .event-cta-delete {
        background: #f44336;
      }
      &-event-cta {
        padding: 10px;
        display: block;
        border-radius: 7px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 10px;
      }
      &-details-information {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
      }
      &-text {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;
      }

      &-cta {
        display: flex;
        justify-content: flex-end;

        & > button {
          padding: 10px 20px;
          background-color: #333;
          color: #fff;
          border: none;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;

          &:hover {
            background-color: #555;
          }
        }
      }

      i {
        color: $color-3;
        @include ltr_direction(margin-right, 5px);
        @include rtl_direction(margin-left, 5px);
      }
    }
  }
}
