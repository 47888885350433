@mixin rtl_direction($proprety, $value) {
  [dir="rtl"] & {
    #{$proprety}: $value;
  }
}

@mixin ltr_direction($proprety, $value) {
  [dir="ltr"] & {
    #{$proprety}: $value;
  }
}
