@import "./hamburger";
/*.navigation {
  z-index: 999999;
  transition: 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  &.nav-animate-down {
    box-shadow: 0 5px 30px rgb(0 0 0 / 10%);
  }
  &.nav-black {
    background-color: #000;
  }
  &.nav-light {
    background-color: white;
    .logo,
    a {
      color: #000 !important;
    }
  }

  nav {
    display: flex;
    align-items: center;

    // Nav Columns
    .nav-column-1 {
      display: flex;
      align-items: center;
      flex-grow: 1;
      .logo {
        color: #fff;
        font-weight: bold;
      }
    }
    .wrapCenter {
      background: #ff5722;
      cursor: pointer;
      padding: 11px;
      border-radius: 8px;
      color: #fff;
      font-weight: bold;
    }

    .nav-column-2 {
    }
    //
    .nav-column-3 {
      li {
        margin-right: 15px;
      }
    }

    .links > ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin: 0;
      > li {
        a {
          font-family: Roboto, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          position: relative;
          display: block;
          text-transform: uppercase;
          padding: 10px 20px;
          text-decoration: none;
          color: #fff;
          z-index: 1;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            transform: scaleY(1.5);
            opacity: 0;
            transition: 0.3s;
          }
          &:after {
            content: "";
            position: absolute;
            top: 1px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            transform: scale(0);
            opacity: 0;
            transition: 0.3s;
            z-index: -1;
          }

          /*&:hover {
            color: #fff;
            &:before {
              transform: scaleY(1);
              opacity: 1;
            }
            &:after {
              transform: scaleY(1);
              opacity: 1;
            }
          }-----
        }
      }
    }
  }
}
*/
/* Responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 662px) {
  .navigation {
    display: none !important;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #fff;
    .nav-wrapper {
      nav {
        display: block;

        .nav-column-1 {
          display: block;
        }

        .links > ul {
          display: inline-block;
          padding: 0;
        }
        .nav-column-3 {
          li {
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
    .nav-hamburger {
      display: block !important;
    }
    &.open {
      display: flex;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 662px) and (max-width: 768px) {
  /* Global */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
