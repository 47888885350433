.event-section {
  background: url("https://lh3.googleusercontent.com/p/AF1QipPiKH0BWmADo-m87L9-vLyckmImJBUh_ZRD29VD=s680-w680-h510");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 100vh;
  display: flex;
  position: relative;
  border-bottom: 10px solid $color-3;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
  }

  .event-wrapper {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .upcoming-match-text {
      margin-top: 40px;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 45px;
      color: #fff;
    }

    .tournament-text {
      margin-bottom: 41px;
      font-weight: bold;
      color: #a3d960;
      font-size: 30px;
    }

    .buy-ticket-btn {
      font-size: 20px;
      position: relative;
      color: #fff;
      z-index: 3;
      padding: 13px 26px;
      text-transform: uppercase;
      font-weight: bold;
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #ff5722;
        @include ltr_direction(transform, skew(-10deg));
        @include rtl_direction(transform, skew(10deg));
        z-index: -1;
      }
      &::before {
        transition: all ease-in 0.25s;
        content: "";
        position: absolute;
        top: 13px;
        @include ltr_direction(left, 10px);
        @include rtl_direction(right, 10px);

        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #81b441;
        border: 4px solid #ff5722;
        @include ltr_direction(transform, skew(-10deg));
        @include rtl_direction(transform, skew(10deg));
        z-index: -1;
        background: transparent;
      }
      &:hover {
        color: #fff;
        &::before {
          top: 0;
          @include ltr_direction(left, 0);
          @include rtl_direction(right, 0);
        }
      }
    }
  }
}

/* Responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 662px) {
  .event-section {
    height: auto !important;
    padding: 110px 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 662px) and (max-width: 768px) {
  /* Global */
  .event-section {
    height: auto !important;
    padding: 110px 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .event-section {
    height: auto !important;
    padding: 110px 0;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
